import * as React from "react"
import PropTypes from "prop-types"
import "./header.css"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
const Header = ({ siteTitle }) => (
  <header className='header'>
    <div className='inner_header'>
      <div className='logo_container'>
      <Link className='linkStyle' to="/">

        <h1>{siteTitle}</h1>

      </Link>

      </div>
      <ul className='navigation'>
      <Link activeStyle={{fontWeight:'bold'}} className='linkStyle' to="/">
        <li>About</li>
        </Link>
        <Link activeStyle={{fontWeight:'bold'}} className='linkStyle' to="/projects/">
        <a><li>Projects</li></a>
        </Link>
        <Link activeStyle={{fontWeight:'bold'}} className='linkStyle' to="/blog">
        <a><li>Blog</li></a>
        </Link>

      </ul>

    </div>


  </header>
)


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
